import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

import {
  useCheckoutDispatch,
  useCheckoutState,
} from "../../state/CheckoutContext"

import CartTotalRow from "./CartTotalRow"
import {
  calcCartSubtotal,
  calcCartTotal,
  isShippableItemInCart,
  isTaxableItemInCart,
} from "../../shared/calcStateItems"
import { CheckoutActionKind } from "../../types/checkoutTypes"
import useCartTotals from "../../hooks/useCartTotals"

interface Props {
  isCouponsEnabled: boolean
  showCouponForm: boolean
  isSubscription?: boolean
  handleRemoveCoupon: () => void
}

const useStyles = makeStyles(theme => ({
  clearIcon: {
    margin: 0,
    padding: 0,
  },
  shippingNotes: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))

const DutiesFeesText = styled.p`
  color: var(--color-grey-3);
  font-size: 0.8rem;
  text-align: right;
`

const Container = styled.div`
  margin-top: 1rem;
  order: 1;
`

const CartTotals = ({
  isCouponsEnabled,
  showCouponForm,
  isSubscription = false,
  handleRemoveCoupon,
}: Props) => {
  const classes = useStyles()
  const {
    currency,
    cartItems,
    taxes,
    shipping,
    couponDiscount,
    shippingNotes,
    couponDescription,
  } = useCheckoutState()

  const [subtotal, total] = useCartTotals()

  const showShipping = isShippableItemInCart(cartItems)
  const showTaxes = isTaxableItemInCart(cartItems)

  return (
    <Container>
      <CartTotalRow
        desc="Subtotal"
        value={subtotal}
        currency={currency}
        isTotal={false}
      />
      {isCouponsEnabled && couponDiscount ? (
        <>
          <CartTotalRow
            desc={`${couponDescription ? couponDescription : "Discount"}`}
            value={couponDiscount ? -couponDiscount : 0}
            currency={currency}
            isTotal={false}
          >
            {showCouponForm && (
              <IconButton
                onClick={handleRemoveCoupon}
                className={classes.clearIcon}
              >
                <DeleteIcon className={classes.clearIcon} />
              </IconButton>
            )}
          </CartTotalRow>
        </>
      ) : null}
      {showShipping && (
        <CartTotalRow
          desc="Shipping"
          value={shipping === 0 ? NaN : shipping}
          currency={currency}
          isTotal={false}
          customNaNMessage=" - "
        />
      )}
      {showTaxes && (
        <CartTotalRow
          desc="Taxes"
          value={taxes === 0 ? NaN : taxes}
          currency={currency}
          isTotal={false}
          customNaNMessage=" - "
        />
      )}
      <CartTotalRow
        desc="Total"
        value={total}
        currency={currency}
        isTotal={true}
      />
      {shippingNotes && <DutiesFeesText>*{shippingNotes}</DutiesFeesText>}
      {isSubscription && (
        <DutiesFeesText>* Plus applicable taxes</DutiesFeesText>
      )}
    </Container>
  )
}

export default CartTotals
