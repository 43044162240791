import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import SimpleContent, {
  ContentSection,
} from "../../../components/sections/SimpleContent"
import { useCheckoutState } from "../../state"

const FeatureWrapper = styled.div`
  & p {
    font-size: 1.1875rem;
    line-height: 2rem;
    margin: 1rem 0;
  }
`

const EmptyCart = () => {
  const { cartItems } = useCheckoutState()
  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          paymentSuccess {
            fields {
              content
              align
              core {
                anchorTag
                title
                titleColor
                subtitle
                subtitleColor
                backgroundColor
                animate
                marginTop
                marginBottom
                underlineTitle
                titleAlign
                divider
                backgroundImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
              featuredImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  const {
    paymentSuccess: { fields },
  }: { paymentSuccess: { fields: ContentSection } } = strapi

  fields.core.title = "Your Cart is Empty"
  fields.core.subtitle = ""
  fields.content = `\n\rReturn to the [store](/store/headset).
    <br/><br/><br/> <br/>
    `

  return !cartItems || cartItems.length === 0 ? (
    <FeatureWrapper id={`empty_cart`}>
      <SimpleContent {...fields} wideImage={true} />
    </FeatureWrapper>
  ) : null
}

export default EmptyCart
