/**
 * The main purpose of this component is to wrap its child with the form provider
 * as the form state needs to be accessed by the methods within that component
 */
import React from "react"

import CartCouponsUI from "./CartCouponsUI"
import { FormContextProvider } from "../../../contexts/formContext"
import { FormInputState } from "../../../utils/validations"

const initialCouponFormInput: Record<string, FormInputState> = {
  couponCode: {
    value: "",
    label: "Coupon Code",
    validation: [{ type: "min", value: 3 }],
  },
}

const CartCoupons = () => {
  return (
    <div style={{ order: 2 }}>
      <FormContextProvider initialInputs={initialCouponFormInput}>
        <CartCouponsUI />
      </FormContextProvider>
    </div>
  )
}

export default CartCoupons
