import React from "react"
import { useMediaQuery, useTheme } from "@material-ui/core"
import styled from "styled-components"

import { incrementCartItem, decrementCartItem } from "../../events"
import { useCheckoutDispatch, useCheckoutState } from "../../state"
import { Button } from "../../../components/ui/Button"

interface Props {
  showActions: boolean
  productId: string
  currentQuantity: number
}

const Container = styled.div<{ isMobile: boolean }>`
  margin-right: ${props => (props.isMobile ? "10px" : "0px")};
  margin-left: ${props => (props.isMobile ? "10px" : "0px")};
  width: ${props => (props.isMobile ? "100%" : "auto")};
  /* display: flex;
  justify-content: flex-end; */
`
const Inner = styled.div<{ isMobile: boolean }>`
  font-size: 1rem;
  margin: ${props => (props.isMobile ? "0 !important" : ".5rem !important")};
  display: flex;
  align-items: center;
`

const CartItemQuantity = ({
  showActions,
  productId,
  currentQuantity,
}: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const checkoutState = useCheckoutState()
  const checkoutDispatch = useCheckoutDispatch()

  return (
    <Container isMobile={isMobile}>
      <Inner isMobile={isMobile}>
        {showActions && (
          <>
            <Button
              onClick={() =>
                decrementCartItem(productId, checkoutState, checkoutDispatch)
              }
              type="secondaryOutline"
              icon="minus"
              size="control"
              sectionId={productId}
              id="remove_one"
              disabled={currentQuantity <= 1}
            />
            <span style={{ margin: "0 8px", fontSize: "1rem" }}>
              {currentQuantity}
            </span>
            <Button
              onClick={() =>
                incrementCartItem(productId, checkoutState, checkoutDispatch)
              }
              type="secondaryOutline"
              icon="plus"
              sectionId={productId}
              id="add_one"
              size="control"
            />
          </>
        )}
      </Inner>
    </Container>
  )
}

export default CartItemQuantity
