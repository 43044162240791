import { Dispatch, SetStateAction } from "react"
import { klaviyoIdentify } from "../../utils/klaviyo"
import { FormActionsType, FormStateType } from "../../contexts/formContext"
import { Action, CheckoutActionKind, State } from "../types/checkoutTypes"
import {
  billingFields,
  giftFields,
  passwordFields,
  shippingFields,
  stripeFields,
} from "../shared/checkoutFormFields"
import { CrmSignupInputs } from "../../hooks/crmSignupHook"
import { SignupOrigin } from "../../hooks/crmSignupHook"
import { SignupInputs } from "../../hooks/signupHook"
import { CheckoutSteps } from "../state"
import { isShippableItemInCart } from "../shared"

interface SignupData {
  CreateUser: CreateUser
}

interface CreateUser {
  firstName: string
  lastName: string
  email: string
  token: string
  exp: string
  updated: string
}

interface SignupError {
  message: string
}

class CheckoutAccountEvents {
  static handleInitialComponentRender(
    email: string | undefined,
    formActions: FormActionsType
  ): void {
    // If we are coming from another page (address confirm), prefill the email address for the guest checkout
    if (email) {
      formActions.setFormData({ email })
    }
  }

  static handleAuthStateUpdate(
    isLoggedIn: boolean,
    checkoutState: State,
    checkoutDispatch: Dispatch<Action>
  ): void {
    if (isLoggedIn) {
      this.goToNextStep(checkoutState, checkoutDispatch)
    }
  }

  /**
   * Called when data is received from server on successful user signup
   * -signup is initiated via this.handleClickContinue
   */
  static handleSignupSuccess(
    signupData: SignupData,
    checkoutState: State,
    checkoutDispatch: Dispatch<Action>,
    authDispatch: Dispatch<any>
  ) {
    if (signupData?.CreateUser) {
      authDispatch({
        type: "LOGIN",
        payload: signupData.CreateUser,
      })
      this.goToNextStep(checkoutState, checkoutDispatch)
    }
  }

  /**
   * Called when error is received from server on user signup
   * -signup is initiated via this.handleClickContinue
   */
  static handleReceiveSignupError(
    signupError: SignupError,
    setGuestCreateAccount: Dispatch<SetStateAction<boolean>>,
    setErrorMessage: Dispatch<SetStateAction<string>>,
    checkoutState: State,
    checkoutDispatch: Dispatch<Action>
  ) {
    if (signupError?.message) {
      setErrorMessage(signupError.message)
    }
  }

  static handleToggleCreateAccount(
    formActions: FormActionsType,
    guestCreateAccount: boolean
  ) {
    // Set fields to validate depending on if user is checking out as a guest or not
    formActions.setSkipValidationFunction((field: string): boolean => {
      if (guestCreateAccount) {
        return [
          ...stripeFields,
          ...shippingFields,
          ...billingFields,
          ...giftFields,
        ].includes(field)
      } else if (!guestCreateAccount) {
        return [
          ...stripeFields,
          ...passwordFields,
          ...shippingFields,
          ...billingFields,
          ...giftFields,
        ].includes(field)
      }
      return false
    })
  }

  static handleClickContinue(
    formActions: FormActionsType,
    setErrorMessage: Dispatch<SetStateAction<string>>,
    formState: FormStateType,
    guestCreateAccount: boolean,
    guestAllowMarketing: boolean,
    checkoutState: State,
    checkoutDispatch: Dispatch<Action>,
    crmSignup: (inputs: CrmSignupInputs) => Promise<void>,
    signup: (inputs: SignupInputs) => Promise<void>
  ) {
    if (!formActions.isValid()) {
      if (window.gtag) {
        window.gtag(
          "event",
          guestCreateAccount
            ? "checkout_guest_signup_info_invalid"
            : "checkout_guest_info_invalid",
          {
            guestCreateAccount,
          }
        )
      }
      return
    }

    setErrorMessage("")
    klaviyoIdentify(formState.inputs.email.value)

    // If the user is not creating an account and is not opting in to receive marketing
    if (!guestCreateAccount && !guestAllowMarketing) {
      // Just move the user forward to the next step
      this.goToNextStep(checkoutState, checkoutDispatch)
      // If the user is not creating an account but is opting in to receive marketing
    } else if (!guestCreateAccount && guestAllowMarketing) {
      // Send CRM signup and go to next step regardless of if CRM signup success or error is received
      crmSignup(CheckoutAccountEvents.getCrmSignupInputs(formState))
      this.goToNextStep(checkoutState, checkoutDispatch)
    }
    if (guestCreateAccount) {
      // Create account and wait for signup data or signup error via useEffect hooks in CheckoutAccount.tsx
      // If signup data is received, this.handleSignupSuccess will be called
      // If signup error is received, this.handleSignupError will be called
      signup(
        CheckoutAccountEvents.getAccountSignupInputs(
          formState,
          guestAllowMarketing
        )
      )
    }
  }

  static goToNextStep(
    checkoutState: State,
    checkoutDispatch: Dispatch<Action>
  ) {
    if (isShippableItemInCart(checkoutState.cartItems)) {
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Shipping },
      })
    } else {
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Billing },
      })
    }
  }

  static getCrmSignupInputs(formState: FormStateType) {
    return {
      email: formState.inputs.email.value,
      firstName: formState.inputs.firstName.value,
      lastName: formState.inputs.lastName.value,
      allowMarketing: true,
      signupOrigin: "GUEST_CHECKOUT" as SignupOrigin,
      phone: formState.inputs.phone?.value,
      organization: formState.inputs.organization?.value,
      city: formState.inputs.city?.value,
      province: formState.inputs.province?.value,
      country: formState.inputs.country?.value,
      comments: formState.inputs.comments?.value,
      practices: [],
      isClinic: false,
    }
  }

  static getAccountSignupInputs(
    formState: FormStateType,
    guestAllowMarketing: boolean
  ) {
    const { inputs } = formState
    const { firstName, lastName, email, password } = inputs
    return {
      firstName,
      lastName,
      email,
      password,
      allowMarketing: { value: guestAllowMarketing },
      signupOrigin: "WEB_ACCOUNT" as SignupOrigin,
    }
  }
}

export default CheckoutAccountEvents
