import React from "react"
import Link from "../../components/ui/link"
import Markdown from "markdown-to-jsx"
import styled from "styled-components"
import Quote from "../../components/ui/Quote"
import SimplePopover from "../../components/ui/SimplePopover"
import { InlineMedia, InlineMediaProps } from "../../components/ui/InlineMedia"

const Content = styled(props => <Markdown {...props} />)`
  & ul {
    list-style-type: disc;
    text-align: left;
  }
  & sup {
    font-size: xx-small;
    vertical-align: super;
  }
  p img {
    width: 100%;
  }
  h3 {
    font-size: 1.2rem;
    line-height: 1.2;
    margin: 0;
    font-weight: 600;
  }
  h4 {
    font-size: 1rem;
    line-height: 1.2;
    margin: 0;
    font-weight: 600;
  }
  p {
    font-size: 1rem;
    line-height: 1.2;
    margin: 0 0 1rem 0;
  }
`

const ModalPromoDescriptionContent = ({
  animate,
  content,
  inlineMedia,
}: {
  animate: boolean
  content: string
  inlineMedia?: InlineMediaProps[]
}) => {
  return (
    <Content
      data-aos={animate && "fade-up"}
      children={content}
      options={{
        forceBlock: true,
        overrides: {
          blockquote: {
            component: Quote,
            props: {
              isBlockQuote: true,
            },
          },
          a: {
            component: Link,
          },
          showmore: {
            component: SimplePopover,
          },
          image: {
            component: InlineMedia,
            props: {
              inlineMedia,
            },
          },
        },
      }}
    />
  )
}

export default ModalPromoDescriptionContent
