import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import styled from "styled-components"

import { FormContextType, useForm } from "../../../contexts/formContext"
import { FormInput } from "../../../components/ui/Input"

type Props = {
  guestCreateAccount: boolean
  setGuestCreateAccount: Function
  guestAllowMarketing: boolean
  setGuestAllowMarketing: Function
}

const Checkboxes = styled.div`
  margin-bottom: 1rem;
`

const CheckoutAccountForm = (props: Props) => {
  const {
    guestCreateAccount,
    setGuestCreateAccount,
    guestAllowMarketing,
    setGuestAllowMarketing,
  } = props
  const { formState, formActions } = useForm() as FormContextType

  const strapiData = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          checkoutCreateAccountMessage
          checkoutNewsletterMessage
        }
      }
    }
  `)

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormInput
            fieldId="user-first-name"
            fieldName="firstName"
            inputs={formState.inputs}
            inputHandler={formActions.inputHandler}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <FormInput
            fieldId="user-last-name"
            fieldName="lastName"
            inputs={formState.inputs}
            inputHandler={formActions.inputHandler}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormInput
            fieldId="user-email"
            fieldName="email"
            fieldType="email"
            inputs={formState.inputs}
            inputHandler={formActions.inputHandler}
          />
        </FormControl>
      </Grid>
      {guestCreateAccount && (
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormInput
                fieldId="user-password"
                fieldName="password"
                fieldType="password"
                inputs={formState.inputs}
                inputHandler={formActions.inputHandler}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <FormInput
                fieldId="user-password-confirm"
                fieldName="passwordConfirm"
                fieldType="password"
                inputs={formState.inputs}
                inputHandler={formActions.inputHandler}
              />
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <Checkboxes>
          <FormControlLabel
            control={
              <Checkbox
                id={"guest-allow-marketing-checkbox"}
                name="guestAllowMarketing"
                value={guestAllowMarketing}
                checked={guestAllowMarketing}
                onChange={() => {
                  setGuestAllowMarketing(!guestAllowMarketing)
                }}
              />
            }
            label={
              strapiData?.siteConfig?.checkoutCreateAccountMessage ||
              "Get the latest intel to a Sharper, Faster & Better Brain, directly to your inbox"
            }
          />
        </Checkboxes>
      </Grid>
    </>
  )
}

export default CheckoutAccountForm
