import React from "react"
import styled from "styled-components"
import Icon from "../../components/ui/Icon"
import { devices } from "../../components/config/breakpoints"
import { ProgressBarItem } from "../types/checkoutTypes"
import { useCheckoutState } from "../state"

const Step = styled.div`
  text-align: center;
  margin: 1rem 3rem;
  position: relative;
  display: inline-block;
  width: 200px;

  @media ${devices.mobile} {
    margin: 0.5rem;
  }
`

const StepMarker = styled.div<{ complete: boolean; active: boolean }>`
  position: relative;
  height: 2.2rem;
  width: 2.2rem;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border: 2px solid
    ${({ complete, active }) =>
      complete
        ? "var(--color-grey-4)"
        : active
        ? "var(--color-teal)"
        : "var(--color-grey-3)"};
  color: ${({ complete, active }) =>
    complete
      ? "var(--color-white)"
      : active
      ? "var(--color-white)"
      : "var(--color-white)"};
  background-color: ${({ complete, active }) =>
    complete
      ? "var(--color-grey-4)"
      : active
      ? "var(--color-teal)"
      : "var(--color-grey-3)"};
`

const Line = styled.span<{ complete: boolean; active: boolean }>`
  @media ${devices.mobile} {
    display: none;
  }
  height: 4px;
  margin-top: 2.2rem;
  margin-right: -4rem;
  margin-left: -4rem;
  z-index: 50;
  width: 100%;
  opacity: ${({ complete, active }) => (active ? "1" : "0.2")};
  background-color: ${({ active }) =>
    active ? "var(--color-teal-st)" : "var(--color-grey-3)"};
`

const StepName = styled.p<{ complete: boolean; active: boolean }>`
  font-size: 0.8rem;
  color: ${({ complete, active }) =>
    active ? "var(--color-teal)" : "var(--color-grey-4)"};
  margin: 0 -4rem;
  @media ${devices.mobile} {
    margin: 0;
  }
`

interface Props {
  item: ProgressBarItem
}

const CheckoutProgressBarItem = ({ item }: Props) => {
  const { currentStep } = useCheckoutState()
  const lastStepInItem =
    item.relatedCheckoutSteps[item.relatedCheckoutSteps.length - 1]
  const isComplete = () => currentStep > lastStepInItem
  const isActive = () => item.relatedCheckoutSteps.includes(currentStep)
  const isLast = () => item.number === 4

  return (
    <>
      <Step>
        <StepMarker complete={isComplete()} active={isActive()}>
          <Icon
            iconType="Solid"
            iconName={isComplete() ? "check" : item.icon}
          />
        </StepMarker>

        <StepName complete={isComplete()} active={isActive()}>
          {item.number}. {item.name}
        </StepName>
      </Step>
      {!isLast() && <Line complete={isComplete()} active={isActive()} />}
    </>
  )
}

export default CheckoutProgressBarItem
