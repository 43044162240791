import { Checkbox, FormControlLabel } from "@material-ui/core"
import React, { useState } from "react"

interface Props {
  isChecked: boolean
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const GiftCheckbox = ({ isChecked, setIsChecked }: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={"gift-headset-checkbox"}
          name="giftHeadsetCheckbox"
          value={isChecked}
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
      }
      label={"This item is a gift"}
    />
  )
}

export default GiftCheckbox
