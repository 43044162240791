import { addToCart as addItemToCart } from "../events"
import { useCheckoutDispatch, useCheckoutState } from "../state"
import { useLazyApi } from "../../hooks/useLazyApi"
import { getQueryParam } from "../../utils/queryParams"
import useClearCart from "./useClearCart"

interface StrapiProduct {
  id: string
  stripeId: string
  stripeTestId: string
}
;[]

const useAddProductQueryParamToCart = (strapiProducts: StrapiProduct[]) => {
  const strapiProductId = getQueryParam("pid")
  const strapiProduct = strapiProducts.find(
    (product: StrapiProduct) => product.id === strapiProductId
  )
  const checkoutState = useCheckoutState()
  const dispatch = useCheckoutDispatch()
  const [sendPxEvent] = useLazyApi("website", "sendPixelEvent")
  const [clearCart] = useClearCart()

  const addProductQueryParamToCart = () => {
    if (strapiProduct) {
      clearCart()
      addItemToCart(
        checkoutState,
        dispatch,
        1,
        strapiProduct.stripeId,
        strapiProduct.stripeTestId,
        sendPxEvent
      )
    }
  }
  return [addProductQueryParamToCart] as const
}

export default useAddProductQueryParamToCart
