import React from "react"
import { makeStyles } from "@material-ui/core/styles"

import Icon from "../../components/ui/Icon"
import styled from "styled-components"

type Props = {
  showFullOrder: boolean
  setShowFullOrder: (e: boolean) => void
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
`

const CheckoutExpandCollapse: React.FC<Props> = ({
  showFullOrder,
  setShowFullOrder,
}) => {
  const toggleShowFullOrder = () => {
    if (showFullOrder) {
      setShowFullOrder(false)
    } else {
      setShowFullOrder(true)
    }
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <Container onClick={toggleShowFullOrder}>
      <Icon
        iconType="Solid"
        size="lg"
        brightColor={true}
        iconName={!showFullOrder ? "chevron-down" : "chevron-up"}
      />
      {!showFullOrder ? "Show Details" : "Hide Details"}
    </Container>
  )
}

export default CheckoutExpandCollapse
