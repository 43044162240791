import React, { useEffect, useState } from "react"
import { useCheckoutDispatch, useCheckoutState } from "../state"
import { useLazyApi } from "../../hooks/useLazyApi"
import { CheckoutActionKind } from "../types/checkoutTypes"
import { FormContextType, useForm } from "../../contexts/formContext"
import CouponEvents from "../events/couponEvents"
import { getLs, removeLs } from "../../utils/localStorage"

type StoredCoupon = { id: string; expiry: string }

const useCoupons = () => {
  const { cartItems, currency, couponCode } = useCheckoutState()
  const checkoutDispatch = useCheckoutDispatch()
  const { formState, formActions } = useForm() as FormContextType

  const [errorMsg, setErrorMsg] = useState("")

  const [verifyCoupon, { loading, error, data }] = useLazyApi(
    "billing",
    "VerifyCoupon"
  )

  // If there is a coupon in local storage (which would be set from a landing page) set it in the form and submit it
  useEffect(() => {
    const lsCoupon = getLs("coupon") as StoredCoupon
    const isCouponExpired = lsCoupon && new Date() > new Date(lsCoupon.expiry)
    // Only set the coupon from localstorage if we haven't verified a coupon yet
    // - we don't want to overwrite a different coupon if the user refreshes the page
    if (!(data && data.VerifyCoupon) && lsCoupon?.id && !isCouponExpired) {
      formActions.setFormData({ couponCode: lsCoupon.id })
      handleSubmitCoupon(lsCoupon.id)
    }
  }, [])

  useEffect(() => {
    if (data && data.VerifyCoupon) {
      checkoutDispatch({
        type: CheckoutActionKind.CLEAR_COUPONS,
      })

      const { couponName, discountCad, discountUsd } = data.VerifyCoupon

      if (data.VerifyCoupon.discountDays) {
        checkoutDispatch({
          type: CheckoutActionKind.SET_COUPON_DISCOUNT_DAYS,
          payload: { days: data.VerifyCoupon.discountDays },
        })
      } else {
        checkoutDispatch({
          type: CheckoutActionKind.SET_COUPON_DISCOUNT,
          payload: { amount: currency === "cad" ? discountCad : discountUsd },
        })
      }

      checkoutDispatch({
        type: CheckoutActionKind.SET_COUPON_CODE,
        payload: { code: formState.inputs.couponCode.value },
      })
      checkoutDispatch({
        type: CheckoutActionKind.SET_COUPON_DESCRIPTION,
        payload: { description: couponName },
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setErrorMsg("Coupon is not eligible for use with this order")
    }
  }, [error])

  useEffect(() => {
    if (couponCode) {
      handleSubmitCoupon(couponCode)
    }
  }, [currency, cartItems])

  const handleSubmitCouponForm = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()

    if (!formActions.isValid()) return

    await handleSubmitCoupon(formState.inputs.couponCode.value)
  }

  const handleSubmitCoupon = async (couponCode: string) => {
    setErrorMsg("")

    const variables = await CouponEvents.getVerifyCouponVariables(
      couponCode,
      currency,
      cartItems
    )

    verifyCoupon({
      variables,
    })
  }

  return [handleSubmitCouponForm, loading, errorMsg] as const
}

export default useCoupons
