import React, { ReactNode, useEffect } from "react"
import styled from "styled-components"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import { formatCurrencyString } from "../../../utils/format"
import { Currency } from "../../types/checkoutTypes"

const SubTotalRow = styled.div<{ isMobile: boolean; isTotal: boolean }>`
  margin: ${props => (props.isMobile ? ".5rem 0" : ".75rem 0")};
  margin-bottom: ${props => (props.isTotal ? "0" : "")};
  text-align: right;
  color: ${props =>
    props.isTotal ? "var(--color-primary-dark)" : "var(--color-grey-3)"};
  display: flex;
  justify-content: flex-end;

  & div {
    font-size: ${props => (props.isTotal ? "1.3rem !important" : "1rem")};
  }
`

const DescriptionColumn = styled.div``

const ValueColumn = styled.div`
  width: 10rem;
`

const CartTotalRow = ({
  value,
  currency,
  desc,
  isTotal,
  customNaNMessage,
  children,
}: {
  value: number
  currency: Currency
  desc: string
  isTotal: boolean
  customNaNMessage?: string
  children?: ReactNode
}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <SubTotalRow
      className={isTotal ? "total" : "subtotal"}
      isMobile={isMobile}
      isTotal={isTotal}
    >
      {children}
      <DescriptionColumn>{desc}</DescriptionColumn>
      <ValueColumn id={`${isTotal ? "cart-total" : ""}`}>
        {isNaN(value)
          ? customNaNMessage
            ? customNaNMessage
            : "-"
          : formatCurrencyString({
              value,
              currency: currency.toUpperCase(),
            })}
        {isTotal ? ` ${currency.toUpperCase()}` : ""}
      </ValueColumn>
    </SubTotalRow>
  )
}

export default CartTotalRow
