import React from "react"

import Layout from "../../../components/Layout"
import CheckoutDashboard from "../../../checkout/components/CheckoutDashboard"
import SEO from "../../../components/Seo"

const CheckoutIndex = () => {
  return (
    <>
      <SEO
        title={"Sens.ai Checkout"}
        description={"Sens.ai online store checkout"}
      />
      <Layout hasHero={false} showStickFooter={false} showFooter={false}>
        <CheckoutDashboard />
      </Layout>
    </>
  )
}

export default CheckoutIndex
