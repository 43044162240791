import { StepInterface, ProgressBarItem } from "../types/checkoutTypes"

export const steps: StepInterface[] = [
  { stepNumber: 0, progressBarNumber: 1, nextText: "Checkout Now" },
  {
    stepNumber: 1,
    progressBarNumber: 1,
    nextText: "Checkout Now",
  },
  {
    stepNumber: 2,
    progressBarNumber: 2,
    nextText: "Continue",
  },
  {
    stepNumber: 3,
    progressBarNumber: 2,
    nextText: "Continue",
  },
  {
    stepNumber: 4,
    progressBarNumber: 3,
  },
  {
    stepNumber: 5,
    progressBarNumber: 4,
  },
]

export const progressBarSteps: ProgressBarItem[] = [
  {
    number: 1,
    name: "Account",
    icon: "shopping-cart",
    relatedCheckoutSteps: [0, 1],
  },
  {
    number: 2,
    name: "Shipping",
    icon: "map-marker-alt",
    relatedCheckoutSteps: [2, 3],
  },
  {
    number: 3,
    name: "Payment",
    icon: "credit-card",
    relatedCheckoutSteps: [4],
  },
  {
    number: 4,
    name: "Complete",
    icon: "box",
    relatedCheckoutSteps: [5],
  },
]
