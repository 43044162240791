import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { CircularProgress, FormControl, useMediaQuery } from "@material-ui/core"

import { FormContextType, useForm } from "../../../contexts/formContext"
import { FormInput } from "../../../components/ui/Input"
import { Button } from "../../../components/ui/Button"
import styled from "styled-components"
import useCoupons from "../../hooks/useCoupons"
import { CheckoutSteps, useCheckoutState } from "../../state"
import theme from "../../../material-ui/theme"

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: theme.spacing(2),
    textAlign: "right",
    flexDirection: "row",
  },
  errorMsg: {
    textAlign: "right",
  },
  marginTop: {
    marginTop: "10px",
  },

  inputSpan: {
    display: "inline",
  },
}))

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isMobile ? "row" : "column")};
  align-items: ${props => (props.isMobile ? "center" : "flex-end")};
  justify-content: ${props => (props.isMobile ? "space-around" : "flex-end")};
  & > span {
    margin-top: ${props => (props.isMobile ? "0" : "1rem")};
  }
`

const BtnSpan = styled.span<{ isMobile: boolean }>`
  display: inline;
  line-height: "3rem";
  margin-top: "1rem";
  margin-left: ${props => (props.isMobile ? ".5rem" : "0")};
`

const CartCouponsUI = () => {
  const classes = useStyles()
  const { formState, formActions } = useForm() as FormContextType
  const [handleSubmitCouponForm, loading, errorMsg] = useCoupons()
  const { couponDiscount, currentStep, couponDiscountDays } = useCheckoutState()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return !couponDiscount &&
    !couponDiscountDays &&
    currentStep < CheckoutSteps.Shipping ? (
    <div>
      <form
        noValidate
        className={classes.form}
        onSubmit={handleSubmitCouponForm}
      >
        <Container isMobile={isMobile}>
          {!loading && (
            <FormControl className={classes.inputSpan}>
              <FormInput
                fieldId="coupon-code"
                fieldName="couponCode"
                inputs={formState.inputs}
                inputHandler={formActions.inputHandler}
              />
            </FormControl>
          )}
          {loading && <CircularProgress />}
          <BtnSpan isMobile={isMobile}>
            <Button
              type="secondaryOutline"
              submit={true}
              id="coupon_code"
              sectionId="checkout_payment" // TODO: Get correct id?
              title="Apply"
              disabled={loading}
              size="control"
            />
          </BtnSpan>
        </Container>
      </form>
      {errorMsg && <p className={`error ${classes.errorMsg}`}>{errorMsg}</p>}
    </div>
  ) : null
}

export default CartCouponsUI
