import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"

import Cart from "./cart/Cart"
import CartCurrency from "./cart/CartCurrency"
import CartItems from "./cart/CartItems"
import CartTotals from "./cart/CartTotals"
import CartCoupons from "./cart/CartCoupons"
import PaddedContainer from "../../components/ui/PaddedContainer"
import EmptyCart from "./cart/EmptyCart"
import Grid from "@material-ui/core/Grid"
import CheckoutProgressBar from "./CheckoutProgressBar"
import CheckoutBox from "./CheckoutBox"
import CheckoutStart from "./steps/CheckoutStart"
import CheckoutAccount from "./steps/CheckoutAccount"
import CheckoutShipping from "./steps/CheckoutShipping"
import CheckoutExpandCollapse from "./CheckoutExpandCollapse"
import CheckoutPaymentWrapper from "./steps/CheckoutPaymentWrapper"
import { useCheckoutDispatch, useCheckoutState } from "../state/CheckoutContext"
import { FormContextProvider } from "../../contexts/formContext"
import { initialFormInput } from "../shared/checkoutFormFields"
import { useAuthState } from "../../contexts/authContext"
import { Dividor as Divider } from "../../components/ui/line"
import { CheckoutSteps } from "../state"
import { isSubscriptionInCart } from "../shared"
import CheckoutMobileTotalBar from "./CheckoutMobileTotalBar"
import { CheckoutActionKind } from "../types/checkoutTypes"
import CartNotifications from "./cart/CartNotifications"
import LoggedInWithMembership from "./LoggedInWithMembership"
import { useLazyApi } from "../../hooks/useLazyApi"
import MembershipPromo from "../../components/MembershipPromo"
import ModalPromo from "./ModalPromo"
import ModalPromoDescription from "./ModalPromoDescription"
import { isHeadsetInCart } from "../shared/calcStateItems"
import GiftAddress from "./cart/GiftAddress"
import { removeFromCart } from "../events"
import { getStripeId } from "../../utils/stripejs"
import { addHeadsetMembershipToCart } from "../events/addHeadsetMembershipToCart"
import useAddProductQueryParamToCart from "../hooks/useAddToCart"

export type SiteConfig = {
  enableCoupons: boolean
  enableBundlePromo: boolean
  membershipMandatory: boolean
  enableAffirmCanada: boolean
  enableAffirmUS: boolean
  toggleCheckoutCardText: string
  toggleCheckoutAffirmText: string
  payWithAffirmMessage: string
  payWithAffirmButtonText: string
}

type StrapiData = {
  strapi: {
    siteConfig: SiteConfig
    membership: StrapiMemberships
    products: {
      id: string
      stripeId: string
      stripeTestId: string
    }[]
  }
}
type StrapiMemberships = {
  mandatoryMembershipCartDescription: string
  memberships: {
    membership: StrapiMembership[]
  }
}

export type StrapiMembership = {
  product: {
    subscriptionPeriod: string
    stripeId: string
    stripeTestId: string
  }
}

const CheckoutDashboard = () => {
  const [isAffirmLoading, setIsAffirmLoading] = useState(false)
  const checkoutState = useCheckoutState()
  const { cartItems, currentStep, accountRequired } = checkoutState
  const checkoutDispatch = useCheckoutDispatch()
  const authState = useAuthState()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [isPaying, setIsPaying] = useState(false)
  const [showFullOrder, setShowFullOrder] = useState(true)
  const [isBuyMembershipModalOpen, setIsBuyMembershipModalOpen] = useState(
    false
  )
  const [
    isMembershipDescriptionModalOpen,
    setIsMembershipDescriptionModalOpen,
  ] = useState(false)
  const [isGiftHeadset, setIsGiftHeadset] = useState(false)

  const hideGoBackButton = currentStep === CheckoutSteps.Start

  const strapiData: StrapiData = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          enableCoupons
          enableBundlePromo
          membershipMandatory
          enableAffirmCanada
          enableAffirmUS
          toggleCheckoutCardText
          toggleCheckoutAffirmText
          payWithAffirmMessage
          payWithAffirmButtonText
        }
        products {
          id
          stripeId
          stripeTestId
        }
        membership {
          mandatoryMembershipCartDescription
          memberships {
            membership {
              product {
                subscriptionPeriod
                stripeId
                stripeTestId
              }
            }
          }
        }
      }
    }
  `)
  const {
    enableCoupons,
    enableBundlePromo,
    membershipMandatory,
  } = strapiData?.strapi?.siteConfig
  const {
    mandatoryMembershipCartDescription,
    memberships,
  } = strapiData?.strapi?.membership

  const collapseAndScroll = () => {
    setShowFullOrder(false)
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  const handleRemoveCoupon = () => {
    checkoutDispatch({
      type: CheckoutActionKind.CLEAR_COUPONS,
    })
  }

  const addAnnualMembershipToCart = () => {
    const annualProduct = memberships.membership.find(
      m => m.product.subscriptionPeriod === "yearly"
    )

    if (annualProduct) {
      addHeadsetMembershipToCart(
        checkoutState,
        checkoutDispatch,
        memberships.membership
      )
    }
  }

  const removeAnnualSubscriptionFromCart = () => {
    const annualProduct = memberships.membership.find(
      m => m.product.subscriptionPeriod === "yearly"
    )
    if (annualProduct) {
      const stripeId = getStripeId(
        annualProduct.product.stripeId,
        annualProduct.product.stripeTestId
      )

      if (stripeId) {
        removeFromCart(stripeId, checkoutState, checkoutDispatch)
      }
    }
  }

  const [fetchOrders, { loading, error, data: userData, errMsg }] = useLazyApi(
    "billing",
    "GetUserOrders"
  )

  const doesUserHaveMembership = userData?.User?.membership?.status === "active"
  const isLoggedInWithMembership =
    doesUserHaveMembership && isSubscriptionInCart(cartItems)

  // When the Dashboard initially mounts, go to the beginning of the Checkout flow
  useEffect(() => {
    checkoutDispatch({
      type: CheckoutActionKind.GO_TO_STEP,
      payload: { stepNumber: CheckoutSteps.Start },
    })
  }, [])

  useEffect(() => {
    if (isMobile) {
      collapseAndScroll()
    }
  }, [currentStep])

  useEffect(() => {
    if (doesUserHaveMembership) return

    const annualProduct = memberships.membership.find(
      m => m.product.subscriptionPeriod === "yearly"
    )

    if (annualProduct) {
      const annualStripeId = getStripeId(
        annualProduct.product.stripeId,
        annualProduct.product.stripeTestId
      )
      const isAnnualSubscriptionInCart =
        annualStripeId &&
        cartItems.map(item => item.productId).includes(annualStripeId)

      if (
        !doesUserHaveMembership &&
        membershipMandatory &&
        isHeadsetInCart(cartItems) &&
        !isAnnualSubscriptionInCart &&
        !isGiftHeadset
      ) {
        addAnnualMembershipToCart()
      }
    }
  }, [cartItems, userData, isGiftHeadset])

  useEffect(() => {
    if (isGiftHeadset && isSubscriptionInCart(cartItems)) {
      removeAnnualSubscriptionFromCart()
    }
  }, [isGiftHeadset])

  useEffect(() => {
    if (authState.isLoggedIn()) {
      fetchOrders({
        variables: {
          email: authState.email,
        },
      })
    }
  }, [authState?.email])

  useEffect(() => {
    if (isLoggedInWithMembership && isSubscriptionInCart(cartItems)) {
      removeAnnualSubscriptionFromCart()
    }
  }, [isLoggedInWithMembership])

  const [addProductQueryParamToCart] = useAddProductQueryParamToCart(
    strapiData.strapi.products
  )
  useEffect(() => {
    addProductQueryParamToCart()
  }, [])

  // Customer is redirected back from Affirm
  // - we need to send them to CheckoutPayment and confirm the payment
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const paymentIntentId = urlParams.get("payment_intent")
    const paymentIntentClientSecret = urlParams.get(
      "payment_intent_client_secret"
    )

    if (paymentIntentId && paymentIntentClientSecret) {
      setIsAffirmLoading(true)
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Payment },
      })
    }
  }, [])

  return (
    <FormContextProvider initialInputs={initialFormInput}>
      <ModalPromo
        setIsBuyMembershipModalOpen={setIsBuyMembershipModalOpen}
        isBuyMembershipModalOpen={isBuyMembershipModalOpen}
      />
      <ModalPromoDescription
        isModalOpen={isMembershipDescriptionModalOpen}
        setIsModalOpen={setIsMembershipDescriptionModalOpen}
      />
      {isMobile && !showFullOrder && (
        <CheckoutMobileTotalBar
          showFullOrder={showFullOrder}
          setShowFullOrder={setShowFullOrder}
        />
      )}
      <PaddedContainer padding="1rem 1rem 0 1rem">
        {cartItems.length > 0 && (
          <div id={`section_payment_success`}>
            {!isMobile && <CheckoutProgressBar />}
            <Grid container spacing={isMobile ? 0 : 6} justifyContent="center">
              {(!isMobile || showFullOrder) && (
                <Cart>
                  {!membershipMandatory &&
                    !isGiftHeadset &&
                    enableBundlePromo &&
                    !isMobile &&
                    currentStep === CheckoutSteps.Start &&
                    !doesUserHaveMembership &&
                    isHeadsetInCart(cartItems) &&
                    !isSubscriptionInCart(cartItems) && (
                      <div>
                        <MembershipPromo
                          setIsBuyMembershipModalOpen={
                            setIsBuyMembershipModalOpen
                          }
                          setIsMembershipDescriptionModalOpen={
                            setIsMembershipDescriptionModalOpen
                          }
                        />
                      </div>
                    )}
                  <CartCurrency currentStep={currentStep} />
                  <Divider color="var(--color-grey-2)" />
                  <CartItems
                    showActions={currentStep === 0}
                    isGiftHeadset={isGiftHeadset}
                    setIsGiftHeadset={setIsGiftHeadset}
                    setIsMembershipDescriptionModalOpen={
                      setIsMembershipDescriptionModalOpen
                    }
                  />
                  {!membershipMandatory &&
                    !isGiftHeadset &&
                    enableBundlePromo &&
                    isMobile &&
                    currentStep === CheckoutSteps.Start &&
                    !doesUserHaveMembership &&
                    isHeadsetInCart(cartItems) &&
                    !isSubscriptionInCart(cartItems) && (
                      <MembershipPromo
                        setIsBuyMembershipModalOpen={
                          setIsBuyMembershipModalOpen
                        }
                        setIsMembershipDescriptionModalOpen={
                          setIsMembershipDescriptionModalOpen
                        }
                      />
                    )}
                  <CartNotifications
                    handleRemoveCoupon={handleRemoveCoupon}
                    data={userData}
                    isMembershipMandatory={membershipMandatory}
                    mandatoryMembershipCartDescription={
                      mandatoryMembershipCartDescription
                    }
                    isGiftHeadset={isGiftHeadset}
                  />
                  {isGiftHeadset && <GiftAddress currentStep={currentStep} />}
                  <CartTotals
                    isCouponsEnabled={enableCoupons}
                    showCouponForm={currentStep < 2}
                    handleRemoveCoupon={handleRemoveCoupon}
                  />
                  {enableCoupons && !isMobile && <CartCoupons />}
                </Cart>
              )}

              {isMobile && showFullOrder && (
                <CheckoutExpandCollapse
                  showFullOrder={showFullOrder}
                  setShowFullOrder={setShowFullOrder}
                />
              )}

              <Grid
                item
                xs={12}
                md={4}
                style={{ marginTop: `${isMobile && "20px"}` }}
              >
                <CheckoutBox
                  isPaying={isPaying}
                  hideBack={hideGoBackButton}
                  showFullOrder={showFullOrder}
                  isLoggedInWithMembership={isLoggedInWithMembership}
                  isAffirmLoading={isAffirmLoading}
                >
                  {isLoggedInWithMembership && <LoggedInWithMembership />}
                  {!isLoggedInWithMembership &&
                    currentStep === CheckoutSteps.Start &&
                    !isAffirmLoading && (
                      <CheckoutStart isGiftHeadset={isGiftHeadset} />
                    )}
                  {!isLoggedInWithMembership &&
                    currentStep === CheckoutSteps.Account && (
                      <CheckoutAccount accountRequired={accountRequired} />
                    )}
                  {!isLoggedInWithMembership &&
                    currentStep === CheckoutSteps.Shipping && (
                      <CheckoutShipping
                        type="shipping"
                        isGiftHeadset={isGiftHeadset}
                      />
                    )}
                  {!isLoggedInWithMembership &&
                    currentStep === CheckoutSteps.Billing && (
                      <CheckoutShipping
                        type="billing"
                        hideCardholderName={true}
                      />
                    )}
                  {!isLoggedInWithMembership &&
                    currentStep === CheckoutSteps.Payment && (
                      <CheckoutPaymentWrapper
                        isPaying={isPaying}
                        setIsPaying={setIsPaying}
                        isGiftHeadset={isGiftHeadset}
                        isAffirmLoading={isAffirmLoading}
                        setIsAffirmLoading={setIsAffirmLoading}
                        siteConfig={strapiData?.strapi?.siteConfig}
                      />
                    )}
                </CheckoutBox>
              </Grid>

              <Grid item>{enableCoupons && isMobile && <CartCoupons />}</Grid>
            </Grid>
          </div>
        )}
      </PaddedContainer>
      {cartItems.length === 0 && (
        <PaddedContainer>
          <EmptyCart />
        </PaddedContainer>
      )}
    </FormContextProvider>
  )
}

export default CheckoutDashboard

// TODOS / NOTES
// - Why is div id `section_payment_success`
