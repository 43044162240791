import { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useAuthDispatch, useAuthState } from "../../contexts/authContext"
import { useCheckoutState, useCheckoutDispatch } from "../state"
import { FormContextType, useForm } from "../../contexts/formContext"
import { useCrmSignup } from "../../hooks/crmSignupHook"
import { useSignup } from "../../hooks/signupHook"
import CheckoutAccountEvents from "../events/checkoutAccountEvents"

const useCheckoutAccount = (email: string | undefined) => {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const dispatch = useAuthDispatch()
  const checkoutDispatch = useCheckoutDispatch()
  const { formState, formActions } = useForm() as FormContextType
  const [crmSignup, crmLoading, crmError] = useCrmSignup() // If we receive a crmErr, we just allow the user to continue, so we do not use the error
  const [signup, signupLoading, signupError, signupData] = useSignup()

  const [guestCreateAccount, setGuestCreateAccount] = useState(true)
  const [guestAllowMarketing, setGuestAllowMarketing] = useState(true)
  const [errorMessage, setErrorMessage] = useState("")

  const strapiData = useStaticQuery(graphql`
    query {
      strapi {
        siteConfig {
          checkoutCreateAccountMessage
          checkoutNewsletterMessage
        }
      }
    }
  `)

  const checkoutCreateAccountMessage =
    strapiData?.siteConfig?.checkoutCreateAccountMessage ||
    "Create an account to track your order"

  const handleClickContinue = () => {
    CheckoutAccountEvents.handleClickContinue(
      formActions,
      setErrorMessage,
      formState,
      guestCreateAccount,
      guestAllowMarketing,
      checkoutState,
      checkoutDispatch,
      crmSignup,
      signup
    )
  }

  useEffect(() => {
    CheckoutAccountEvents.handleInitialComponentRender(email, formActions)
  }, [])

  useEffect(() => {
    CheckoutAccountEvents.handleReceiveSignupError(
      signupError,
      setGuestCreateAccount,
      setErrorMessage,
      checkoutState,
      checkoutDispatch
    )
  }, [signupError])

  useEffect(() => {
    CheckoutAccountEvents.handleSignupSuccess(
      signupData,
      checkoutState,
      checkoutDispatch,
      dispatch
    )
  }, [signupData])

  useEffect(() => {
    CheckoutAccountEvents.handleAuthStateUpdate(
      authState.isLoggedIn(),
      checkoutState,
      checkoutDispatch
    )
  }, [authState])

  useEffect(() => {
    CheckoutAccountEvents.handleToggleCreateAccount(
      formActions,
      guestCreateAccount
    )
  }, [guestCreateAccount])

  return [
    handleClickContinue,
    guestCreateAccount,
    setGuestCreateAccount,
    checkoutCreateAccountMessage,
    guestAllowMarketing,
    setGuestAllowMarketing,
    crmLoading,
    signupLoading,
    errorMessage,
  ] as const
}

export default useCheckoutAccount
