import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Divider } from "@material-ui/core"
import Icon from "../../components/ui/Icon"
import { useCheckoutState } from "../state"
import { calcCartTotal } from "../shared/calcStateItems"

type Props = {
  showFullOrder: boolean
  setShowFullOrder: (s: boolean) => void
}

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    top: 78,
    zIndex: 1000,
    width: "100%",
    backgroundColor: "white",
  },
  summary: {
    padding: theme.spacing(2),
    paddingBottom: "0",
  },
  summaryItem: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "center",
    paddingBottom: theme.spacing(1),
  },
  summaryItemSmall: {
    display: "flex",
    fontSize: "0.85rem",
    justifyContent: "space-between",
    alignContent: "center",
    paddingBottom: theme.spacing(1),
    marginRight: "0",
  },
  icon: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignContent: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
}))

const CheckoutMobileTotalBar: React.FC<Props> = ({
  setShowFullOrder,
  showFullOrder,
}) => {
  const classes = useStyles()

  const state = useCheckoutState()
  const { cartItems, currency, taxes, shipping, couponDiscount } = state

  const [shippingDisplay, setDisplayShipping] = useState(false)
  const [taxDisplay, setDisplayTax] = useState(false)
  const [discountDisplay, setDisplayDiscount] = useState(false)
  const [addedRows, setAddedRows] = useState(0)

  const cartTotal = calcCartTotal(
    cartItems,
    currency,
    taxes,
    shipping,
    couponDiscount
  )

  useEffect(() => {
    setDisplayShipping(state.shipping > 0)
    setDisplayTax(state.taxes > 0)
    setDisplayDiscount(state.couponDiscount > 0)

    let addedRowsInit = state.shipping > 0 ? 1 : 0
    addedRowsInit += state.taxes > 0 ? 1 : 0
    addedRowsInit += state.couponDiscount ? 1 : 0
    setAddedRows(addedRowsInit)
  }, [state])

  const toggleShowFullOrder = () => {
    setShowFullOrder(!showFullOrder)
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className={classes.root} onClick={toggleShowFullOrder}>
      <Box className={classes.summary}>
        {discountDisplay && (
          <Box className={classes.summaryItemSmall}>
            <div>
              <span>Discount</span>
            </div>
            <div>
              <span>
                ${parseFloat((state.couponDiscount / 100.0).toFixed(2))}
              </span>
            </div>
          </Box>
        )}
        {shippingDisplay && (
          <Box className={classes.summaryItemSmall}>
            <div>
              <span>Shipping</span>
            </div>
            <div>
              <span>${parseFloat((state.shipping / 100.0).toFixed(2))}</span>
            </div>
          </Box>
        )}
        {taxDisplay && (
          <Box className={classes.summaryItemSmall}>
            <div>
              <span>Taxes</span>
            </div>
            <div>
              <span>${parseFloat((state.taxes / 100.0).toFixed(2))}</span>
            </div>
          </Box>
        )}
        <Box className={classes.summaryItem}>
          <div>
            <span>Your total</span>
          </div>
          <div>
            <span>${parseFloat((cartTotal / 100.0).toFixed(2))}</span>
          </div>
        </Box>
      </Box>
      <div className={classes.icon}>
        <Icon
          iconType="Solid"
          size="1x"
          iconName={!showFullOrder ? "chevron-down" : "chevron-up"}
        />
      </div>
      <Divider />
    </div>
  )
}

export default CheckoutMobileTotalBar
