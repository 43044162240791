import React from "react"
import styled from "styled-components"
import { Button } from "./ui/Button"
import { graphql, useStaticQuery } from "gatsby"
import MembershipPromoDetailsTrigger from "./MembershipPromoDetailsTrigger"

interface Props {
  setIsBuyMembershipModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsMembershipDescriptionModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
}

const Container = styled.div``

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 30px 30px 0px 0px;
  background: var(
    --GreenBlueGradient,
    linear-gradient(90deg, #3cd8b0 0%, #5c38bd 100%)
  );
`

const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 12px 24px 12px;
  background: #f6f7f8;
  border-radius: 0px 0px 30px 30px;
`

const Heading = styled.h3`
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 109.091% */
  letter-spacing: 0.22px;
  text-transform: uppercase;
  margin: 0 0 0.2rem 0;
`

const Subtitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.18px;
`

const AddText = styled.div`
  color: #000;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 133.333% */
  letter-spacing: 0.18px;
  margin-right: 1rem;
`

const MembershipPromo = ({
  setIsBuyMembershipModalOpen,
  setIsMembershipDescriptionModalOpen,
}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        membership {
          promoTitle
          promoSubtitle
          promoAddText
          promoButtonText
          promoDetailsTitle
        }
      }
    }
  `)
  const {
    promoTitle,
    promoSubtitle,
    promoAddText,
    promoButtonText,
    promoDetailsTitle,
  } = data?.strapi?.membership

  return (
    <Container>
      <Top>
        <Heading>{promoTitle ?? "Limited time offer"}</Heading>
        <Subtitle>
          {promoSubtitle ?? "Buy Your Membership Now and Get a Free Trial"}
        </Subtitle>
      </Top>
      <Bottom>
        <AddText>{promoAddText ?? "Add a Sens.ai Membership"}</AddText>
        <Button
          type="primary"
          sectionId="membership_promo"
          title={promoButtonText ?? "CHOOSE"}
          size="control"
          onClick={() => setIsBuyMembershipModalOpen(true)}
        />
      </Bottom>
      <MembershipPromoDetailsTrigger
        setIsMembershipDescriptionModalOpen={
          setIsMembershipDescriptionModalOpen
        }
        onClickText={promoDetailsTitle}
      />
    </Container>
  )
}

export default MembershipPromo
