import React from "react"
import styled from "styled-components"

import CheckoutProgressBarItem from "./CheckoutProgressBarItem"
import { devices } from "../../components/config/breakpoints"
import { progressBarSteps } from "../state/checkoutSteps"

const StepContainer = styled.div`
  display: flex;
  justify-content: space-around;

  margin-bottom: 2rem;
  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
`

const CheckoutProgressBar = ({}: {}) => {
  return (
    <StepContainer>
      {progressBarSteps.map((step, index) => (
        <CheckoutProgressBarItem key={step.number} item={step} />
      ))}
    </StepContainer>
  )
}

export default CheckoutProgressBar
