import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  setIsMembershipDescriptionModalOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >
  onClickText: string
}

const DescriptionLink = styled.div`
  color: #1d99b8;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  margin-top: 1rem;
  cursor: pointer;
  text-align: center;
`

const MembershipPromoDetailsTrigger = ({
  setIsMembershipDescriptionModalOpen,
  onClickText,
}: Props) => {
  return (
    <DescriptionLink onClick={() => setIsMembershipDescriptionModalOpen(true)}>
      {onClickText ?? "What do I get with a membership?"}
    </DescriptionLink>
  )
}

export default MembershipPromoDetailsTrigger
