import React from "react"
import styled from "styled-components"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Divider from "@material-ui/core/Divider"

import {
  useCheckoutState,
  useCheckoutDispatch,
} from "../../state/CheckoutContext"
import { Currency, CheckoutActionKind } from "../../types/checkoutTypes"
import Icon from "../../../components/ui/Icon"
import { useMediaQuery } from "@material-ui/core"
import theme from "../../../material-ui/theme"
import { setLs } from "../../../utils/localStorage"

interface Props {
  currentStep: number
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const CurrencyDropdown = styled.div`
  margin-left: "3em";
`

const CurrencyDropButton = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-teal-light);
  font-family: var(--font-family-header);
`

const Title = styled.h3<{ isMobile: boolean }>`
  margin: 0;
  font-size: ${props => (props.isMobile ? "1.2rem" : "1.5rem")};
`

const curriencies: Currency[] = ["cad", "usd"]

const CartCurrency = ({ currentStep }: Props) => {
  const checkoutState = useCheckoutState()
  const checkoutDispatch = useCheckoutDispatch()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelectCurrency = (currency: Currency) => {
    checkoutDispatch({
      type: CheckoutActionKind.SET_CURRENCY,
      payload: { currency: currency },
    })
    setLs("currency", currency)
    setAnchorEl(null)
  }

  return (
    <Container>
      <Title isMobile={isMobile}>Your Order:</Title>
      {currentStep === 0 && (
        <CurrencyDropdown>
          <CurrencyDropButton onClick={handleClick}>
            {checkoutState.currency}{" "}
            <Icon
              iconName="caret-down"
              iconType="Solid"
              iconColor="var(--color-grey-3)"
              size={"xs"}
            />
          </CurrencyDropButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            MenuListProps={{ onMouseLeave: () => setAnchorEl(null) }}
          >
            <Divider />
            {curriencies.map(currency => {
              return (
                <MenuItem
                  key={currency}
                  onClick={() => handleSelectCurrency(currency)}
                  selected={currency === checkoutState.currency}
                >
                  {currency.toUpperCase()}
                </MenuItem>
              )
            })}
          </Menu>
        </CurrencyDropdown>
      )}
    </Container>
  )
}

export default CartCurrency
