import React, { SetStateAction, Dispatch } from "react"
import styled from "styled-components"

import Modal from "../../components/ui/Modal"
import { useMediaQuery } from "@material-ui/core"
import theme from "../../material-ui/theme"
import { useStaticQuery, graphql } from "gatsby"
import MarkdownContent from "../../components/ui/MarkdownContent"
import ModalPromo from "./ModalPromo"
import ModalPromoDescriptionContent from "./ModalPromoDescriptionContent"

const Wrapper = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: ${({ isMobile }) => (isMobile ? "100%" : "90vh")};
  width: ${({ isMobile }) => (isMobile ? "100%" : "90vw")};
  max-width: 800px;
  max-height: 600px;
`

interface Props {
  isModalOpen: boolean
  setIsModalOpen: Dispatch<SetStateAction<boolean>>
}

const ModalPromoDescription = ({ isModalOpen, setIsModalOpen }: Props) => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const data = useStaticQuery(graphql`
    query {
      strapi {
        membership {
          promoDetailsText
        }
      }
    }
  `)

  return (
    <Modal setIsOpen={setIsModalOpen} isOpen={isModalOpen} wide={true}>
      <Wrapper isMobile={isMobile}>
        <ModalPromoDescriptionContent
          content={data?.strapi?.membership?.promoDetailsText || ""}
          animate={false}
        />
      </Wrapper>
    </Modal>
  )
}

export default ModalPromoDescription
