/**
 * This is the top level component of the User's cart
 */

import React, { useEffect } from "react"
import Grid from "@material-ui/core/Grid"

import { useCheckoutState } from "../../../checkout/state"
import usePixelEvent from "../../hooks/usePixelEvent"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Cart: React.FC = ({ children }) => {
  const checkoutState = useCheckoutState()
  const [executePixelViewCart] = usePixelEvent("view_cart")

  const executeViewCartEvent = () => {
    if (typeof window === `undefined` || !checkoutState) {
      return
    }

    executePixelViewCart()
  }

  useEffect(() => {
    executeViewCartEvent()
  }, [])

  return (
    <>
      <Grid item xs={12} sm={12} md={8}>
        <Container>{children}</Container>
      </Grid>
    </>
  )
}

export default Cart
