import { useEffect, useState } from "react"
import { useAuthState } from "../../contexts/authContext"
import { CheckoutSteps, useCheckoutDispatch, useCheckoutState } from "../state"
import usePixelEvent from "./usePixelEvent"
import { CartProductWithQuantity } from "../../ts/interfaces"
import { getCartProduct } from "../../utils/stripejs"
import { klaviyoTrackBehavior } from "../../utils/klaviyo"
import { calcCartTotal, isShippableItemInCart } from "../shared/calcStateItems"
import { CheckoutActionKind } from "../types/checkoutTypes"
import { FormContextType, useForm } from "../../contexts/formContext"
import {
  allFields,
  billingFields,
  passwordFields,
  shippingFields,
  stripeFields,
  userFields,
  vatFields,
} from "../shared/checkoutFormFields"

const useCheckoutStart = (isGiftHeadset: boolean) => {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const { cartItems, currency, taxes, shipping, couponDiscount } = checkoutState
  const checkoutDispatch = useCheckoutDispatch()
  const [executePixelInitiateCheckout] = usePixelEvent("InitiateCheckout")
  const { formState, formActions } = useForm() as FormContextType

  const [checkoutInitiated, setCheckoutInitiated] = useState(false)
  const [checkoutMounted, setCheckoutMounted] = useState(false)

  useEffect(() => {
    formActions.setSkipValidationFunction((field: string): boolean => {
      return [...allFields].includes(field)
    })
  }, [])
  useEffect(() => {
    if (!isGiftHeadset) {
      formActions.setSkipValidationFunction((field: string): boolean => {
        return [...allFields].includes(field)
      })
    } else {
      formActions.setSkipValidationFunction((field: string): boolean => {
        return [
          ...userFields,
          ...passwordFields,
          ...stripeFields,
          ...vatFields,
          ...shippingFields,
          ...billingFields,
        ].includes(field)
      })
    }
  }, [isGiftHeadset])

  const executeInitiateCheckoutEvent = async () => {
    // send checkout initialization to fb pixel
    if (checkoutInitiated || typeof window === `undefined`) {
      return
    }

    setCheckoutInitiated(true)

    await executePixelInitiateCheckout()

    // TODO: extract this into a hook
    if (window._learnq) {
      const cartProducts: CartProductWithQuantity[] = []

      cartItems.forEach(cartItem => {
        const product = getCartProduct(cartItem.productId)
        if (product && cartItem.quantity) {
          cartProducts.push({
            ...product,
            quantity: cartItem.quantity,
          })
        }
      })

      setTimeout(() => {
        //use a small delay to give time for queue to process new accounts
        klaviyoTrackBehavior("Started Checkout", {
          content_type: "product",
          content: [
            ...cartProducts.map(product => ({
              id: product?.id,
              name: product?.name,
              quantity: product?.quantity,
            })),
          ],
          value: parseFloat(
            (
              calcCartTotal(
                cartItems,
                currency,
                taxes,
                shipping,
                couponDiscount
              ) / 100.0
            ).toFixed(2)
          ),
          currency: currency,
        })
      }, 1000)
    }
  }

  const handleClickContinue = async () => {
    if (!formActions.isValid()) return

    await executeInitiateCheckoutEvent()

    if (!authState.isLoggedIn()) {
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Account },
      })
    } else if (isShippableItemInCart(checkoutState.cartItems)) {
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Shipping },
      })
    } else {
      checkoutDispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Billing },
      })
    }
  }

  const resetServerGeneratedCalculatedStateItems = () => {
    checkoutDispatch({
      type: CheckoutActionKind.SET_TAXES,
      payload: { amount: 0 },
    })
    checkoutDispatch({
      type: CheckoutActionKind.SET_SHIPPING,
      payload: { amount: 0 },
    })
    checkoutDispatch({
      type: CheckoutActionKind.CLEAR_COUPONS,
    })
  }

  useEffect(() => {
    setCheckoutMounted(true)

    // If we're starting a new checkout or navigating back to the checkout
    // reset the state items that are calculated by the server, since the user
    // is able to modify the cart item quantities and currency in the CheckoutStart component
    resetServerGeneratedCalculatedStateItems()
  }, [])

  return [handleClickContinue, executeInitiateCheckoutEvent]
}

export default useCheckoutStart
