import React, { useEffect } from "react"
import { useCheckoutDispatch } from "../state"
import { CheckoutActionKind } from "../types/checkoutTypes"
import { removeLs } from "../../utils/localStorage"

const useClearCart = () => {
  const checkoutDispatch = useCheckoutDispatch()

  const clearCart = () => {
    checkoutDispatch({ type: CheckoutActionKind.CLEAR_CART })
    checkoutDispatch({
      type: CheckoutActionKind.GO_TO_STEP,
      payload: { stepNumber: 0 },
    })
    removeLs("cart")
    removeLs("cartItems")
    removeLs("coupon")
  }
  return [clearCart] as const
}

export default useClearCart
