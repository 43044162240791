import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button } from "../../components/ui/Button"
import { useLogout } from "../../hooks/useLogout"
import Modal from "../../components/ui/Modal"
import { useAuthState } from "../../contexts/authContext"
import Login from "../../components/auth/Login"
import { SectionTitle } from "../../components/ui/Title"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.p`
  /* color: var(--color-primary-dark); */
  text-align: center;
`

const LoggedInWithMembership = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoginMode, setIsLoginMode] = useState(false)

  const logout = useLogout()
  const authState = useAuthState()

  return (
    <div>
      <Modal
        setIsOpen={setIsModalOpen}
        isOpen={isModalOpen && !authState.isLoggedIn()}
      >
        <Login
          startLoginMode={isLoginMode}
          redirectUrl={""}
          isPurchasing={true}
          email={""}
        />
      </Modal>
      <Content>
        <SectionTitle
          title="Existing Membership"
          subtitle=""
          gradient={true}
          titleAlign="center"
        />
        <Text>
          This account already has an active membership. To purchase a
          membership for another user, please logout and create an account or
          login to an account for that user.
        </Text>
        <Button
          type="link"
          icon="arrow-right"
          title={`No account yet? Create one`}
          size="regular"
          sectionId="checkout"
          id="existing_account_login"
          // gTagEvent="Start Checkout Existing Account"
          onClick={() => {
            logout()
            setIsLoginMode(false)
            setIsModalOpen(true)
          }}
        />
        <Button
          type="link"
          icon="arrow-right"
          title={`Log in as another user`}
          size="regular"
          sectionId="checkout"
          id="existing_account_login"
          // gTagEvent="Start Checkout Existing Account"
          onClick={() => {
            logout()
            setIsLoginMode(true)
            setIsModalOpen(true)
          }}
        />
      </Content>
    </div>
  )
}

export default LoggedInWithMembership
