import React, { useEffect, useState } from "react"
import styled from "styled-components"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import Login from "../../../components/auth/Login"
import { Button } from "../../../components/ui/Button"
import Modal from "../../../components/ui/Modal"
import { SectionTitle } from "../../../components/ui/Title"
import { useAuthState } from "../../../contexts/authContext"
import { useCheckoutState } from "../../state"
import { doesPurchaseRequireAccount } from "../../shared/calcStateItems"
import useCheckoutStart from "../../hooks/useCheckoutStart"

interface Props {
  isGiftHeadset: boolean
}

const MarginBottom = styled.div<{ isMobile: boolean }>`
  margin-bottom: ${props => (props.isMobile ? "1rem" : "2rem")};
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const UserInfo = styled.div<{ isMobile?: boolean }>`
  text-align: center;
  font-size: 1.2rem;
  margin-top: ${props => (props.isMobile ? "0" : "1rem")};
  margin-bottom: ${props => (props.isMobile ? "0" : "1rem")};
  color: var(--color-purple);
`

const CheckoutStart = ({ isGiftHeadset }: Props) => {
  const authState = useAuthState()
  const checkoutState = useCheckoutState()
  const { cartItems } = checkoutState

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const [modalOpen, setModalOpen] = useState(false)
  const [loginMode, setLoginMode] = useState(true)

  const [handleClickContinue, executeInitiateCheckoutEvent] = useCheckoutStart(
    isGiftHeadset
  )

  return (
    <div>
      <Modal
        setIsOpen={setModalOpen}
        isOpen={modalOpen && !authState.isLoggedIn()}
      >
        <Login
          startLoginMode={loginMode}
          redirectUrl={"/store/checkout"}
          isPurchasing={true}
          email={""}
        />
      </Modal>
      {/* {!isMobile && (
        <SectionTitle
          title="Checkout"
          subtitle=""
          gradient={true}
          titleAlign="center"
        />
      )} */}

      <ButtonsContainer>
        {!authState.isLoggedIn() && doesPurchaseRequireAccount(cartItems) ? (
          <UserInfo>Start checkout by creating an account</UserInfo>
        ) : !isMobile && authState.isLoggedIn() ? (
          <UserInfo>You are logged in as {authState.email}.</UserInfo>
        ) : (
          isGiftHeadset && <UserInfo>Start checkout with your gift</UserInfo>
        )}

        <MarginBottom isMobile={isMobile}>
          <Button
            type="primary"
            size="regular"
            sectionId={authState.isLoggedIn() ? "checkout_acc" : "checkout"}
            id={authState.isLoggedIn() ? "continue" : "now"}
            gTagEvent={
              authState.isLoggedIn()
                ? "Start Checkout Logged In"
                : "Start Checkout Not Logged In"
            }
            icon="shopping-cart"
            title="Checkout Now"
            onClick={handleClickContinue}
          />
        </MarginBottom>

        {isMobile && authState.isLoggedIn() && (
          <UserInfo isMobile={isMobile}>
            You are logged in as {authState.email}.
          </UserInfo>
        )}

        {!authState.isLoggedIn() && (
          <>
            {/* <Button
              type={doesPurchaseRequireAccount(cartItems) ? "primary" : "link"}
              icon={
                doesPurchaseRequireAccount(cartItems)
                  ? undefined
                  : "arrow-right"
              }
              title={
                doesPurchaseRequireAccount(cartItems)
                  ? "Start Checkout"
                  : `No account yet? Create one`
              }
              size="regular"
              sectionId="checkout"
              id="existing_account_login"
              gTagEvent="Start Checkout Existing Account"
              onClick={async () => {
                setLoginMode(false)
                await executeInitiateCheckoutEvent()
                // setModalOpen(true)
              }}
            />
            {doesPurchaseRequireAccount(cartItems) && (
              <div style={{ marginBottom: "1em" }}></div>
            )} */}
            <Button
              type={"link"}
              icon={"arrow-right"}
              title={`Already a User? Login Here`}
              size="regular"
              sectionId="checkout"
              id="login"
              gTagEvent="Start Checkout Existing Account"
              onClick={async () => {
                setLoginMode(true)
                await executeInitiateCheckoutEvent()
                setModalOpen(true)
              }}
            />
          </>
        )}
      </ButtonsContainer>
    </div>
  )
}

export default CheckoutStart
