import { FormControl, Grid, useMediaQuery } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { FormInput } from "../../../components/ui/Input"
import { FormContextType, useForm } from "../../../contexts/formContext"
import { CheckoutSteps } from "../../state"
import theme from "../../../material-ui/theme"
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  currentStep: number
}

const Box = styled.div`
  background: #f3f6f8;
  padding: 1.5rem 2rem;
`

const FormContainer = styled.div<{ isMobile: boolean }>`
  width: ${props => (props.isMobile ? "100%" : "80%")};
`

const Title = styled.div`
  padding-bottom: 1rem;
  color: #808080;
`

const EmailDisclaimer = styled.div`
  color: var(--color-primary);
  margin-left: 1rem;
  font-size: 0.9rem;
  font-style: italic;
  line-height: 1.2;
`

const GiftAddress = ({ currentStep }: Props) => {
  const { formState, formActions } = useForm() as FormContextType
  const isDisabled = currentStep !== CheckoutSteps.Start
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const { strapi } = useStaticQuery(
    graphql`
      query {
        strapi {
          gift {
            giftCartFormDescription
          }
        }
      }
    `
  )

  return (
    <Box>
      {currentStep === CheckoutSteps.Start ? (
        <>
          <Title>Please enter the name and email of the gift recipient:</Title>
          <FormContainer isMobile={isMobile}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormInput
                    fieldId="gift-first-name"
                    fieldName="giftFirstName"
                    inputs={formState.inputs}
                    inputHandler={formActions.inputHandler}
                    disabled={isDisabled}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormInput
                    fieldId="gift-last-name"
                    fieldName="giftLastName"
                    inputs={formState.inputs}
                    inputHandler={formActions.inputHandler}
                    disabled={isDisabled}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <FormInput
                    fieldId="gift-email"
                    fieldName="giftEmail"
                    inputs={formState.inputs}
                    inputHandler={formActions.inputHandler}
                    disabled={isDisabled}
                  />
                </FormControl>
              </Grid>
              <EmailDisclaimer>
                {strapi?.gift?.giftCartFormDescription ??
                  "We will not spoil the surprise. This email will only be used to claim the gift, and not to contact the gift recipient. The gift recipient can change the email address at a later time."}
              </EmailDisclaimer>
            </Grid>
          </FormContainer>
        </>
      ) : (
        <p>
          {`You will receive an email voucher with unique code for ${formState.inputs.giftFirstName.value} ${formState.inputs.giftLastName.value} to redeem on our website. You can forward this voucher or print it off. The code can be redeemed once only.`}
        </p>
      )}
    </Box>
  )
}

export default GiftAddress
