import { Dispatch } from "react"
import getStripe, { getCartProduct, getStripeId } from "../../utils/stripejs"
import { State, Action, CheckoutActionKind } from "../types/checkoutTypes"
import { setLs } from "../../utils/localStorage"
import { getUpdatedCartItems } from "../shared"
import { StrapiMembership } from "../components/CheckoutDashboard"

export const addHeadsetMembershipToCart = (
  state: State,
  dispatch: Dispatch<Action>,
  strapiMemberships: StrapiMembership[]
) => {
  const { cartItems } = state
  let updatedCartItems = state.cartItems

  const annualProduct = strapiMemberships.find(
    m => m.product.subscriptionPeriod === "yearly"
  )
  const monthlyProduct = strapiMemberships.find(
    m => m.product.subscriptionPeriod === "monthly"
  )

  if (monthlyProduct) {
    const monthlyStripeId = getStripeId(
      monthlyProduct.product.stripeId,
      monthlyProduct.product.stripeTestId
    )
    const isMonthlySubscriptionInCart =
      monthlyStripeId &&
      cartItems.map(item => item.productId).includes(monthlyStripeId)

    if (isMonthlySubscriptionInCart) {
      dispatch({
        type: CheckoutActionKind.REMOVE_FROM_CART,
        payload: { cartItemId: monthlyStripeId },
      })
      updatedCartItems = updatedCartItems.filter(
        item => item.productId !== monthlyStripeId
      )
    }
  }

  if (annualProduct) {
    const annualStripeId = getStripeId(
      annualProduct.product.stripeId,
      annualProduct.product.stripeTestId
    )

    if (annualStripeId) {
      dispatch({
        type: CheckoutActionKind.ADD_TO_CART,
        payload: { id: annualStripeId, quantity: 1 },
      })

      if (!updatedCartItems.find(item => item.productId === annualStripeId)) {
        updatedCartItems.push({
          productId: annualStripeId,
          quantity: 1,
        })
      }
    }
  }

  setLs("cartItems", updatedCartItems)
}
