import { useEffect } from "react"
import { useApiMutation } from "./useApiMutation"
import { getLs } from "../utils/localStorage"
import { getParamMetaData, paramIsValid } from "../utils/queryParams"
import { klaviyoIdentify } from "../utils/klaviyo"

export type SignupOrigin = "GUEST_CHECKOUT" | "NEWSLETTER" | "WEB_ACCOUNT"

export interface CrmSignupInputs {
  firstName: string
  lastName: string
  email: string
  phone?: string
  signupOrigin: SignupOrigin
  organization?: string
  city?: string
  province?: string
  country?: string
  comments?: string
  practices: string[]
  isClinic: boolean
}

export const useCrmSignup = () => {
  const [
    signup,
    { loading: crmLoading, error: crmError, data: crmData },
  ] = useApiMutation("account", "SignupCrm")

  const crmSignup = async (inputs: CrmSignupInputs) => {
    const {
      firstName,
      lastName,
      email,
      signupOrigin,
      phone,
      organization,
      city,
      province,
      country,
      comments,
      practices,
      isClinic,
    } = inputs

    klaviyoIdentify(email)

    const metadata = await getParamMetaData()
    const fields = [
      "affiliateId",
      "campaignId",
      "personaId",
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
      "utm_id",
    ]
    const meta: any = {}
    metadata.forEach(m => {
      if (fields.includes(m.key) && m.value) {
        meta[m.key] = m.value
      }
    })

    const input = {
      email,
      firstName: firstName,
      lastName: lastName,
      allowMarketing: true,
      signupOrigin,
      ...meta,
      phone,
      organization,
      city,
      province,
      country,
      comments,
      practices,
      isClinic,
    }

    signup({
      variables: {
        input,
      },
    })
  }

  return [crmSignup, crmLoading, crmError, crmData]
}
