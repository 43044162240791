import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Paper, useMediaQuery } from "@material-ui/core"

import { Button } from "../../components/ui/Button"
import { useCheckoutState, useCheckoutDispatch } from "../state"
import { checkoutGoBack } from "../events/checkoutGoToStep"
import { CheckoutSteps } from "../state"
import { useAuthState } from "../../contexts/authContext"
import theme from "../../material-ui/theme"

type Props = {
  showFullOrder: boolean
  isPaying: boolean
  hideBack: boolean
  isLoggedInWithMembership: boolean
  isAffirmLoading: boolean
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    borderRadius: 15,
  },
  button: {
    minHeight: 0,
  },
}))

const CheckoutBox: React.FC<Props> = ({
  children,
  isPaying,
  hideBack,
  showFullOrder,
  isLoggedInWithMembership,
  isAffirmLoading,
}) => {
  const classes = useStyles(showFullOrder)
  const authState = useAuthState()
  const checkoutDispatch = useCheckoutDispatch()
  const checkoutState = useCheckoutState()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const { currentStep, taxes, couponDiscount, shipping } = checkoutState

  const [addedRows, setAddedRows] = useState(0)

  useEffect(() => {
    let addedRowsInit = shipping > 0 ? 1 : 0
    addedRowsInit += taxes > 0 ? 1 : 0
    addedRowsInit += couponDiscount ? 1 : 0
    setAddedRows(addedRowsInit)
  }, [shipping, taxes, couponDiscount])

  const handleClickBack = () => {
    checkoutGoBack(checkoutState, checkoutDispatch, authState.isLoggedIn())
  }

  return !isMobile ? (
    <Paper
      className={classes.root}
      elevation={10}
      style={{ marginTop: showFullOrder ? "0" : `${addedRows * 40 + 60}px` }}
    >
      {children}
      {currentStep > CheckoutSteps.Start && !hideBack && (
        <Button
          className={classes.button}
          title={"Go back"}
          type="text"
          sectionId="checkout_acc"
          onClick={handleClickBack}
          icon="arrow-left"
          disabled={isPaying}
        />
      )}
    </Paper>
  ) : (
    <>
      <div
        style={{
          marginTop: showFullOrder ? "0" : `${addedRows * 40 + 60}px`,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {children}
        {currentStep > CheckoutSteps.Start &&
          !hideBack &&
          !isLoggedInWithMembership && (
            <Button
              className={classes.button}
              title={"Go back"}
              type="text"
              sectionId="checkout_acc"
              onClick={handleClickBack}
              icon="arrow-left"
              disabled={isPaying || isAffirmLoading}
            />
          )}
      </div>
    </>
  )
}

export default CheckoutBox
