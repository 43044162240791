import React, { SetStateAction, Dispatch } from "react"
import styled from "styled-components"

import Cart from "./cart/Cart"
import Modal from "../../components/ui/Modal"
import { Dividor as Divider } from "../../components/ui/line"
import CartItems from "./cart/CartItems"
import { useMediaQuery } from "@material-ui/core"
import theme from "../../material-ui/theme"
import { Con } from "../../components/ui/PaddedContainer"
import { Button } from "../../components/ui/Button"
import { graphql, useStaticQuery } from "gatsby"
import MembershipSection from "../../components/sections/MembershipSection"
import Title, { SectionTitle } from "../../components/ui/Title"

const Wrapper = styled.div<{ isMobile: boolean }>`
  position: relative;
  /* height: ${({ isMobile }) => (isMobile ? "100%" : "90vh")}; */
  width: ${({ isMobile }) => (isMobile ? "100%" : "90vw")};
  max-width: 800px;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

// const Container = styled.div`
//   border-radius: 30px;
//   background: #f6f7f8;
// `

// const ItemContainer = styled.div`
//   padding: 1rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `

// const IconContainer = styled.div`
//   width: 55px;
//   height: 55px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const ContentContainer = styled.div`
//   display: flex;
//   flex-direction: column;
// `

// const Title = styled.div`
//   color: #5c38bd;
//   font-size: 16px;
//   font-weight: 700;
//   line-height: 1.2;
//   letter-spacing: 0.19px;
// `

// const Subtitle = styled.div`
//   color: #000;
//   font-size: 14px;
//   font-weight: 400;
//   line-height: 1.5;
//   letter-spacing: 0.16px;
// `

interface Props {
  isBuyMembershipModalOpen: boolean
  setIsBuyMembershipModalOpen: Dispatch<SetStateAction<boolean>>
}

const ModalPromo = ({
  isBuyMembershipModalOpen,
  setIsBuyMembershipModalOpen,
}: Props) => {
  const data = useStaticQuery(graphql`
    query {
      strapi {
        membership {
          memberships {
            id
            buttonText
            membership {
              product {
                name
                stripeId
                stripeTestId
                isSubscription
                subscriptionPeriod
                isBilledMonthly
                isBilledYearly
              }
            }
          }
        }
      }
    }
  `)

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Modal
      setIsOpen={setIsBuyMembershipModalOpen}
      isOpen={isBuyMembershipModalOpen}
      wide={true}
      // title="Choose Your Membership"
    >
      <Wrapper isMobile={isMobile}>
        <SectionTitle title="Choose Your Membership" gradient={true} />
        {data?.strapi?.membership?.memberships && (
          <MembershipSection
            {...data.strapi.membership.memberships}
            setIsModalOpen={setIsBuyMembershipModalOpen}
            isModalOpen={isBuyMembershipModalOpen}
            isPromo={true}
          />
        )}
        {/* <Container>
          <ItemContainer>
            <IconContainer>Icon</IconContainer>
            <ContentContainer>
              <Title>Title</Title>
              <Subtitle>Subtitle</Subtitle>
            </ContentContainer>
            <Button
              type="primary"
              sectionId="membership_promo_modal"
              title="SELECT"
              size="control"
              onClick={() => handleClickSelect()}
            />
          </ItemContainer>
        </Container> */}
      </Wrapper>
    </Modal>
  )
}

export default ModalPromo
