import React, { Dispatch, SetStateAction } from "react"

import { getCartProduct } from "../../../utils/stripejs"
import { useCheckoutState } from "../../state/CheckoutContext"

import CartItem from "./CartItem"

interface Props {
  showActions: boolean
  isGiftHeadset: boolean
  setIsGiftHeadset: Dispatch<SetStateAction<boolean>>
  setIsMembershipDescriptionModalOpen: Dispatch<SetStateAction<boolean>>
}

const CartItems = ({
  showActions,
  isGiftHeadset,
  setIsGiftHeadset,
  setIsMembershipDescriptionModalOpen,
}: Props) => {
  const checkoutState = useCheckoutState()
  const { cartItems } = checkoutState

  return (
    <>
      {cartItems
        .sort((a, b) => {
          const aProd = getCartProduct(a.productId)?.prices?.cad?.type
          const bProd = getCartProduct(b.productId)?.prices?.cad?.type
          if (aProd === "recurring" && bProd === "one_time") {
            return 1
          }
          return 0
        })
        .map(item => {
          const { productId } = item
          const cartProd = getCartProduct(productId)
          if (!cartProd) {
            return <></>
          }
          return (
            <CartItem
              key={productId}
              cartProd={cartProd}
              quantity={
                cartItems.find(item => item.productId === productId)
                  ?.quantity || 0
              }
              showActions={showActions}
              isGiftHeadset={isGiftHeadset}
              setIsGiftHeadset={setIsGiftHeadset}
              setIsMembershipDescriptionModalOpen={
                setIsMembershipDescriptionModalOpen
              }
            />
          )
        })}
    </>
  )
}

export default CartItems
