import React, { useState } from "react"
import styled from "styled-components"
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"

import Modal from "../../../components/ui/Modal"
import Login from "../../../components/auth/Login"
import CheckoutAccountForm from "./CheckoutAccountForm"
import { SectionTitle } from "../../../components/ui/Title"
import { Button } from "../../../components/ui/Button"
import useCheckoutAccount from "../../hooks/useCheckoutAccount"
import { doesPurchaseRequireAccount } from "../../shared/calcStateItems"
import { useCheckoutState } from "../../state"
import { useAuthState } from "../../../contexts/authContext"

interface Props {
  accountRequired: boolean
  email?: string
}

const ButtonContainer = styled.div<{ isMobile: boolean }>`
  margin-bottom: ${({ isMobile }) => (isMobile ? "1rem" : "2rem")};
  display: flex;
  justify-content: center;
`

const CheckoutAccount = ({ accountRequired, email }: Props) => {
  const [
    handleClickContinue,
    guestCreateAccount,
    setGuestCreateAccount,
    checkoutCreateAccountMessage,
    guestAllowMarketing,
    setGuestAllowMarketing,
    crmLoading,
    signupLoading,
    errorMessage,
  ] = useCheckoutAccount(email)
  const checkoutState = useCheckoutState()
  const { cartItems } = checkoutState
  const authState = useAuthState()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [modalOpen, setModalOpen] = useState(false)
  const [loginMode, setLoginMode] = useState(true)

  return (
    <div>
      <Modal
        setIsOpen={setModalOpen}
        isOpen={modalOpen && !authState.isLoggedIn()}
      >
        <Login
          startLoginMode={loginMode}
          redirectUrl={"/store/checkout"}
          isPurchasing={true}
          email={""}
        />
      </Modal>
      <>
        <SectionTitle
          title="Checkout"
          subtitle=""
          gradient={true}
          titleAlign="center"
        />
        <Grid container spacing={2} justifyContent="center">
          <Button
            type={"link"}
            icon={"arrow-right"}
            title={`Already a User? Login Here`}
            size="regular"
            sectionId="checkout"
            id="login"
            gTagEvent="Start Checkout Existing Account"
            onClick={async () => {
              setLoginMode(true)
              setModalOpen(true)
            }}
          />
          {!doesPurchaseRequireAccount(cartItems) && (
            <Grid item xs={12} style={{ textAlign: "left" }}>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={"guest-create-account-checkbox"}
                      name="guestCreateAccount"
                      value={guestCreateAccount}
                      checked={guestCreateAccount}
                      onChange={() => {
                        setGuestCreateAccount(!guestCreateAccount)
                      }}
                    />
                  }
                  label={
                    checkoutCreateAccountMessage ||
                    "Create an account to track your order"
                  }
                />
              </div>
            </Grid>
          )}
          <CheckoutAccountForm
            guestCreateAccount={guestCreateAccount}
            setGuestCreateAccount={setGuestCreateAccount}
            guestAllowMarketing={guestAllowMarketing}
            setGuestAllowMarketing={setGuestAllowMarketing}
          />
        </Grid>
        {(!accountRequired || guestCreateAccount) && (
          <ButtonContainer isMobile={isMobile}>
            <Button
              type="primary"
              icon="user"
              size="regular"
              sectionId="checkout"
              id={guestCreateAccount ? "create_account" : "guest"}
              gTagEvent={
                guestCreateAccount
                  ? "Create Account Checkout"
                  : "Checkout As Guest"
              }
              title={`Continue${!guestCreateAccount ? ` as Guest` : ""}`}
              disabled={crmLoading || signupLoading}
              onClick={handleClickContinue}
            />
          </ButtonContainer>
        )}
        {errorMessage && <p className="error">{errorMessage}</p>}
      </>
    </div>
  )
}

export default CheckoutAccount
