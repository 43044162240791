import { getParamMetaData } from "../../utils/queryParams"
import { getCartProduct } from "../../utils/stripejs"
import { CartItem, Currency } from "../types/checkoutTypes"

class CouponEvents {
  static async getVerifyCouponVariables(
    couponCode: string,
    currency: Currency,
    cartItems: CartItem[]
  ) {
    let items = []

    for (const item of cartItems) {
      const product = getCartProduct(item.productId)
      const priceId = product ? product.prices[currency]?.id : null
      const quantity = item.quantity

      if (priceId) {
        items.push({ priceId, quantity })
      }
    }

    // Coupon affiliates and campaigns
    const meta = await getParamMetaData()
    let affiliateId = ""
    let campaignId = ""

    for (const item of meta) {
      if (item.key === "affiliateId") {
        affiliateId = item.value as string
      } else if (item.key === "campaignId") {
        campaignId = item.value as string
      }
    }

    return {
      code: couponCode,
      items,
      affiliateId,
      campaignId,
    }
  }
}

export default CouponEvents
