import { Dispatch } from "react"
import { Action, CheckoutActionKind, State } from "../types/checkoutTypes"
import { CheckoutSteps } from "../state"
import { isShippableItemInCart } from "../shared"

export const checkoutGoBack = (
  state: State,
  dispatch: Dispatch<Action>,
  isLoggedIn: boolean
) => {
  switch (state.currentStep) {
    case CheckoutSteps.Start:
      return
    case CheckoutSteps.Account:
      dispatch({
        type: CheckoutActionKind.GO_TO_STEP,
        payload: { stepNumber: CheckoutSteps.Start },
      })
      break
    case CheckoutSteps.Shipping:
      if (isLoggedIn) {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Start },
        })
      } else {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Account },
        })
      }
      break
    case CheckoutSteps.Billing:
      if (isLoggedIn) {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Start },
        })
      } else {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Account },
        })
      }
      break
    case CheckoutSteps.Payment:
      if (isShippableItemInCart(state.cartItems)) {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Shipping },
        })
      } else {
        dispatch({
          type: CheckoutActionKind.GO_TO_STEP,
          payload: { stepNumber: CheckoutSteps.Billing },
        })
      }
      break
    default:
      break
  }
}
