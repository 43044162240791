import React, { useEffect, useState } from "react"
import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

import { useCheckoutState } from "../../state"
import { formatDate } from "../../../utils/format"
import { useLazyApi } from "../../../hooks/useLazyApi"
import { UserType } from "../../../components/user/Dashboard"
import { useApi } from "../../../hooks/useApi"
import { isTrialAvailable, trialDaysLeftText } from "../../../utils/memberships"
import { useAuthState } from "../../../contexts/authContext"
import {
  getSubscriptionInCartTrialDays,
  isHeadsetAndMembershipInCart,
  isHeadsetInCart,
  isHeadsetSubscriptionPromoApplicable,
} from "../../shared/calcStateItems"

interface Props {
  handleRemoveCoupon: () => void
  data: any
  isMembershipMandatory: boolean
  mandatoryMembershipCartDescription: string
  isGiftHeadset: boolean
}

const Container = styled.div`
  display: flex;
`
const Text = styled.p``

const CartNotifications = ({
  handleRemoveCoupon,
  data,
  isMembershipMandatory,
  mandatoryMembershipCartDescription,
  isGiftHeadset,
}: Props) => {
  const { couponDiscountDays, cartItems } = useCheckoutState()
  const authState = useAuthState()
  const [user, setUser] = useState<UserType | null>(null)

  const getTrialEndDate = (trialDays: number) => {
    const trialEndDate = new Date()
    trialEndDate.setDate(trialEndDate.getDate() + trialDays)

    return formatDate({ date: trialEndDate, fullMonth: true })
  }

  useEffect(() => {
    if (data?.User) {
      setUser(data.User)
    }
  }, [data])

  const renderCouponText = () => {
    return (
      <Container>
        <IconButton onClick={handleRemoveCoupon}>
          <DeleteIcon />
        </IconButton>
        <Text>
          The coupon you have entered entitles you to a {couponDiscountDays} day
          trial period. You will not be charged until{" "}
          {getTrialEndDate(couponDiscountDays)}.
        </Text>
      </Container>
    )
  }

  const renderHeadsetText = () => {
    return (
      <Container>
        <Text>
          {user !== null && isTrialAvailable(user) && trialDaysLeftText(user)}
        </Text>
      </Container>
    )
  }

  const renderMandatoryMembershipText = () => {
    return <Text>{mandatoryMembershipCartDescription}</Text>
  }

  const renderPromoText = () => {
    const trialDays = getSubscriptionInCartTrialDays(cartItems)
    return (
      <Text>
        {`Unlock the full Sens.ai experience from the start with a 1-year membership. Buy your headset and membership bundle for a ${trialDays}-day trial with no billing until after you’ve experienced the difference. Register through the Sens.ai App to begin.`}
      </Text>
    )
  }

  const showMandatoryMembership =
    isMembershipMandatory &&
    isHeadsetAndMembershipInCart(cartItems) &&
    !isGiftHeadset
  const showPromo =
    isHeadsetSubscriptionPromoApplicable(cartItems) &&
    couponDiscountDays < getSubscriptionInCartTrialDays(cartItems)
  const showCoupon =
    couponDiscountDays > 0 &&
    (!isHeadsetSubscriptionPromoApplicable(cartItems) ||
      couponDiscountDays > getSubscriptionInCartTrialDays(cartItems))

  return showMandatoryMembership
    ? renderMandatoryMembershipText()
    : showPromo
    ? renderPromoText()
    : showCoupon
    ? renderCouponText()
    : user !== null
    ? renderHeadsetText()
    : null
}

export default CartNotifications
